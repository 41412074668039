<template>
  <div
    :class="[
      $vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4',
      'py-16',
    ]"
    style="background: #0099ff"
  >
    <!-- 3 features -->
    <v-row justify="center" class="py-16 px-16 text-center">
      <v-col
        :cols="12"
        :sm="10"
        :md="4"
        v-for="(pf, i) in platformFeatures"
        :key="i"
        :class="[$vuetify.breakpoint.lgAndUp ? 'px-8' : '']"
      >
        <v-card height="100%" color="#0099ff" elevation="0">
          <div class="text-center">
            <v-avatar color="#202531" size="128px">
              <v-icon size="128px" color="white" class="pa-6">
                {{ pf.icon }}
              </v-icon>
            </v-avatar>
          </div>
          <v-card-title class="justify-center">
            <div class="text-h6 font-weight-bold">
              {{ pf.title }}
              <div class="card__feature__underline"></div>
            </div>
          </v-card-title>
          <v-card-subtitle
            :class="[
              $vuetify.breakpoint.lgAndUp ? 'px-16' : '',
              'py-5 text-body-1 white--text',
            ]"
          >
            {{ pf.subtitle }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiAccountMultiple, mdiChartLine, mdiContentCopy } from "@mdi/js";
export default {
  data() {
    return {
      platformFeatures: [
        {
          icon: mdiContentCopy,
          title: "Accurate Tests",
          subtitle:
            "Just tell us what skills you need and we will automatically setup an assessment.",
        },
        {
          icon: mdiAccountMultiple,
          title: "Created by experts",
          subtitle:
            "A team of experienced industry experts curate the skill tests.",
        },
        {
          icon: mdiChartLine,
          title: "Grow quickly",
          subtitle: "Focus on growing your product. Leave hiring to us.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.card__feature__underline {
  border-bottom: 6px solid #165fd8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>