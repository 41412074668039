var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4', 'py-16']
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-16",
    attrs: {
      "cols": 12,
      "md": "4"
    }
  }, [_c('v-row', {
    staticClass: "pl-10"
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h4 font-weight-medium pb-4"
  }, [_vm._v(" The "), _c('span', {
    staticStyle: {
      "color": "#165fd8"
    }
  }, [_vm._v("fastest way to ")])]), _c('div', {
    staticClass: "text-h2 font-weight-bold mb-4"
  }, [_vm._v("Hire Great Talent")]), _c('div', {
    staticClass: "font-weight-bold text-h6 grey--text"
  }, [_vm._v(" Use CodeDrills to quickly setup up assessment tests according to your hiring needs. ")]), _c('v-btn', {
    staticClass: "mt-16 rounded-xl font-weight-bold",
    attrs: {
      "href": "mailto:hello@codedrills.io"
    }
  }, [_vm._v(" Contact Us ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "px-16 py-8",
    attrs: {
      "cols": 12,
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require('../../assets/images/campaign/bg.png')
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }