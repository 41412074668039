<template>
  <div
    id="camp__signup"
    :class="[
      $vuetify.breakpoint.lgAndUp ? 'camp__signup__padding' : 'px-8',
      'camp__signup pb-16',
    ]"
  >
    <v-sheet
      :class="[
        'rounded-xl camp__signup__card',
        $vuetify.breakpoint.mdAndUp ? 'mx-16' : '',
      ]"
      elevation="8"
    >
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card
            dark
            elevation="0"
            height="100%"
            :class="[
              $vuetify.breakpoint.mdAndUp
                ? 'rounded-tl-xl rounded-bl-xl ml-3 px-5'
                : '',
              '',
            ]"
            style="background: #202531"
          >
            <v-card-title class="py-5 text-h5 font-weight-bold">
              Sign Up Now
            </v-card-title>
            <v-card-subtitle class="text-h6">
              Hiring great talent is hard work and time-consuming. Use our
              carefully crafted tests to efficiently screen candidates.
            </v-card-subtitle>
            <v-card-text class="text-center mx-auto">
              <img
                :src="require('../../assets/images/campaign/signup.png')"
                alt="Sign up now"
                width="60%"
              />
            </v-card-text>
            <v-card-actions class="text-subtitle-2 font-weight-bold pb-5 pl-5">
              Get started now with our free plan
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            elevation="0"
            height="100%"
            class="rounded-tr-xl rounded-br-xl"
          >
            <v-card-title class="text-h5 font-weight-bold">
              Get Your Free Trial
            </v-card-title>
            <v-card-text class="py-8">
              <v-row v-if="!submitted">
                <v-col cols="12" md="10">
                  <v-form ref="signup_form" v-model="formValidity">
                    <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      label="Name"
                      placeholder="Enter name"
                      class=""
                      outlined
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="workEmail"
                      :rules="emailRules"
                      label="Work Email ID"
                      placeholder="Enter Work Email ID"
                      outlined
                      required
                    ></v-text-field>
                    <v-btn
                      :disabled="!formValidity"
                      color="#165FD8"
                      class="py-6 mt-10 rounded-xl white--text"
                      @click.prevent="submit"
                      :loading="clickedSubmit"
                    >
                      Start Now
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
              <div class="text-body-1 black--text" v-else>
                Thank you for subscribing! We will let you know when we release!
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import firebase from 'firebase';
import { mapActions } from "vuex";
export default {
  data() {
    return {
      formValidity: true,
      submitted: false,
      clickedSubmit: false,
      workEmail: "",
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    ...mapActions("customer", ["customerSignUp"]),
    generatePass() {
      let chars =
        "0123456789abcdefghijklmnopqrstuvwxyz-ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let pass = "";
      for (let i = 0; i < 24; i++) {
        pass += chars[Math.floor(Math.random() * chars.length)];
      }
      return pass;
    },
    submit() {
      this.$refs.signup_form.validate() && this.doSignUp();
    },
    doSignUp() {
      console.log({ name: this.name, email: this.workEmail });
      this.clickedSubmit = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.workEmail, this.generatePass())
        .then((res) => {
          console.log(`custlog`, res);
          // set name then call api
          res.user
            .updateProfile({
              displayName: this.name,
            })
            .then((_) => {
              this.customerSignUp({
                name: this.name,
                email: this.workEmail,
              }).then((r) => {
                console.log(`custSignup:`, r);
                this.submitted = true;
              });
            });
        })
        .catch((error) => {
          console.log(error.message, error);
          if (error.code === "auth/email-already-in-use") {
            console.log(`reroute to login: `);
            // this.$router.push('/loginCustomer');
          }
        })
        .finally(() => {
          // check
        });
    },
  },
};
</script>
<style scoped>
/* https://edavis.github.io/2018/02/04/create-an-angled-background-in-css/ */
.camp__signup {
  position: relative;
  background-image: linear-gradient(
    -0.15rad,
    #165fd8 64%,
    transparent calc(64% + 2px)
  );
  /* border-top: 5px solid red; */
}
.camp__signup__card {
  position: relative;
  top: -4rem;
}
.camp__signup__padding {
  padding-left: 16rem;
  padding-right: 16rem;
}
</style>