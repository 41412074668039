var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4', 'py-16'],
    staticStyle: {
      "background": "#0099ff"
    }
  }, [_c('v-row', {
    staticClass: "py-16 px-16 text-center",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.platformFeatures, function (pf, i) {
    return _c('v-col', {
      key: i,
      "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'px-8' : ''],
      attrs: {
        "cols": 12,
        "sm": 10,
        "md": 4
      }
    }, [_c('v-card', {
      attrs: {
        "height": "100%",
        "color": "#0099ff",
        "elevation": "0"
      }
    }, [_c('div', {
      staticClass: "text-center"
    }, [_c('v-avatar', {
      attrs: {
        "color": "#202531",
        "size": "128px"
      }
    }, [_c('v-icon', {
      staticClass: "pa-6",
      attrs: {
        "size": "128px",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(pf.icon) + " ")])], 1)], 1), _c('v-card-title', {
      staticClass: "justify-center"
    }, [_c('div', {
      staticClass: "text-h6 font-weight-bold"
    }, [_vm._v(" " + _vm._s(pf.title) + " "), _c('div', {
      staticClass: "card__feature__underline"
    })])]), _c('v-card-subtitle', {
      "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'px-16' : '', 'py-5 text-body-1 white--text']
    }, [_vm._v(" " + _vm._s(pf.subtitle) + " ")])], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }