var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "white--text"
  }, [_c('NavCampaign'), _c('Hero'), _c('Pricing'), _c('Features'), _c('Customers'), _c('SignupCard'), _c('Footer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }