<template>
  <div
    :class="[
      $vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4',
      'pb-16',
    ]"
  >
    <!-- title -->
    <div class="text-h3 text-center">
      Pick a plan that works for you
      <div class="price__title__underline"></div>
    </div>
    <!-- 3 cards -->
    <v-row justify="center" class="py-10 px-4">
      <v-col
        v-for="(plan, i) in plans"
        :key="i"
        :cols="10"
        :sm="5"
        :lg="3"
        class="mx-5"
      >
        <v-card height="100%" class="rounded-xl d-flex flex-column" elevation="10">
          <v-card-title>
            <div class="text-lg-h4 text-md-h5 font-weight-medium py-5">
              {{ plan.title }}
            </div>
            <div class="text-subtitle-2 py-5 pr-8">
              {{ plan.subtitle }}
            </div>
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text class="py-5 black--text">
            <h1 class="font-weight-medium pb-5">
              ₹{{ formatCurrency(plan.price) }}<span class="text-subtitle-1">/month</span>
            </h1>
            <!-- <div class="text-subtitle-2">
              Billed at ₹{{ plan.price * 12 }}/year
            </div> -->
            <div class="">
              <v-checkbox
                v-for="(feat, i) in plan.features"
                :key="`${plan.title}-${feat.text}-${i}`"
                readonly
                v-model="feat.active"
                :label="feat.text"
                :color="feat.active ? 'info' : ''"
                :value="feat.active"
                :disabled="!feat.active"
                hide-details
              ></v-checkbox>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="justify-center pb-6">
            <v-btn class="mr-5 px-4 py-2" rounded color="#165FD8" @click="scrollToId('camp__signup')">
              <!-- find a better way to customize -->
              <span style="color: white"> Start Free Trial </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import scrollToMixin from "../../mixins/scrollToMixin";
export default {
  data() {
    return {
      plans: [
        {
          title: "Free",
          subtitle: "",
          price: 0,
          features: [
            { active: true, text: "10 Candidates / mo" },
            { active: true, text: "5 Assessments / mo" },
            { active: true, text: "10 Skill Tests" },
            { active: true, text: "1 Custom Question Per Assessment" },
            { active: true, text: "Custom questions - MCQ, subjective" },
            { active: false, text: "Onboarding and training" },
            { active: false, text: "Support SLA" },
            { active: false, text: "API Access" },
            { active: false, text: "SSO Support" },
          ],
          actionTo: "/pay",
        },
        {
          title: "Scale",
          subtitle: "",
          price: 15000,
          features: [
            { active: true, text: "1000 Candidates / mo" },
            { active: true, text: "10 Assessments / mo" },
            { active: true, text: "100+ Skill Tests" },
            { active: true, text: "10 Custom Question Per Assessment" },
            {
              active: true,
              text: "Custom questions - MCQ, subjective, video, project upload",
            },
            { active: false, text: "Onboarding and training" },
            { active: false, text: "Support SLA" },
            { active: false, text: "API Access" },
            { active: false, text: "SSO Support" },
          ],
          actionTo: "/pay",
        },
        {
          title: "Enterprise",
          subtitle: "",
          price: 125000,
          features: [
            { active: true, text: "10000 Candidates / mo" },
            { active: true, text: "50 Assessments / mo" },
            { active: true, text: "100+ Skill Tests" },
            { active: true, text: "20 custom questions per assessment" },
            {
              active: true,
              text: "Custom questions - MCQ, subjective, video, project upload",
            },
            { active: true, text: "Onboarding and training" },
            { active: true, text: "Support SLA" },
            { active: true, text: "API Access" },
            { active: true, text: "SSO Support" },
          ],
          actionTo: "/pay",
        },
      ],
    };
  },
  methods: {
    formatCurrency(price) {
      return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(price);
    }
  },
  mixins: [scrollToMixin]
};
</script>
<style scoped>
.v-card__title {
  background-color: #165fd8;
  color: white;
}
.price__title__underline {
  border-bottom: 6px solid #165fd8;
  width: 15%;
  padding-top: 16px;
  margin-left: 62%;
}
</style>
