var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "top__nav"
  }, [_c('v-app-bar', {
    staticClass: "hidden-sm-and-down",
    attrs: {
      "fixed": ""
    }
  }, [_c('v-list-item-avatar', {
    staticClass: "ml-16"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-avatar', {
    attrs: {
      "color": "#165FD8"
    }
  }, [_c('v-icon', {
    staticClass: "pr-2 pl-1 pb-1",
    attrs: {
      "color": "white",
      "size": "42px"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiConsoleLine) + " ")])], 1)], 1)], 1), _c('v-app-bar-title', {
    staticClass: "text-h5 font-weight-medium"
  }, [_c('div', {
    staticClass: "pr-8"
  }, [_vm._v(" CodeDrills ")])]), _c('v-spacer'), _vm._l(_vm.menuItems, function (item) {
    return _c('v-btn', {
      key: item.title,
      staticClass: "mr-5",
      attrs: {
        "rounded": "",
        "color": "#165FD8",
        "href": item.path ? item.path : ''
      },
      on: {
        "click": function click($event) {
          !!item.clickId && _vm.scrollToId("".concat(item.clickId));
        }
      }
    }, [_c('span', {
      staticClass: "white--text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]);
  })], 2), _c('v-app-bar', {
    staticClass: "hidden-md-and-up",
    attrs: {
      "fixed": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c('v-list-item-avatar', {
    staticClass: "mx-2"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-avatar', {
    attrs: {
      "color": "#165FD8"
    }
  }, [_c('v-icon', {
    staticClass: "pr-2 pl-1",
    attrs: {
      "color": "white",
      "size": "42px"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiConsoleLine) + " ")])], 1)], 1)], 1), _c('v-app-bar-title', {
    staticClass: "text-h5 font-weight-medium"
  }, [_c('div', [_vm._v(" CodeDrills ")])]), _c('v-spacer')], 1), _c('v-navigation-drawer', {
    attrs: {
      "fixed": ""
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-divider'), _c('v-list', _vm._l(_vm.menuItems, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "link": "",
        "to": item.path
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))])], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }