var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'camp__signup__padding' : 'px-8', 'camp__signup pb-16'],
    attrs: {
      "id": "camp__signup"
    }
  }, [_c('v-sheet', {
    "class": ['rounded-xl camp__signup__card', _vm.$vuetify.breakpoint.mdAndUp ? 'mx-16' : ''],
    attrs: {
      "elevation": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    "class": [_vm.$vuetify.breakpoint.mdAndUp ? 'rounded-tl-xl rounded-bl-xl ml-3 px-5' : '', ''],
    staticStyle: {
      "background": "#202531"
    },
    attrs: {
      "dark": "",
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "py-5 text-h5 font-weight-bold"
  }, [_vm._v(" Sign Up Now ")]), _c('v-card-subtitle', {
    staticClass: "text-h6"
  }, [_vm._v(" Hiring great talent is hard work and time-consuming. Use our carefully crafted tests to efficiently screen candidates. ")]), _c('v-card-text', {
    staticClass: "text-center mx-auto"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/campaign/signup.png'),
      "alt": "Sign up now",
      "width": "60%"
    }
  })]), _c('v-card-actions', {
    staticClass: "text-subtitle-2 font-weight-bold pb-5 pl-5"
  }, [_vm._v(" Get started now with our free plan ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-tr-xl rounded-br-xl",
    attrs: {
      "elevation": "0",
      "height": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-bold"
  }, [_vm._v(" Get Your Free Trial ")]), _c('v-card-text', {
    staticClass: "py-8"
  }, [!_vm.submitted ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-form', {
    ref: "signup_form",
    model: {
      value: _vm.formValidity,
      callback: function callback($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.nameRules,
      "label": "Name",
      "placeholder": "Enter name",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "label": "Work Email ID",
      "placeholder": "Enter Work Email ID",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.workEmail,
      callback: function callback($$v) {
        _vm.workEmail = $$v;
      },
      expression: "workEmail"
    }
  }), _c('v-btn', {
    staticClass: "py-6 mt-10 rounded-xl white--text",
    attrs: {
      "disabled": !_vm.formValidity,
      "color": "#165FD8",
      "loading": _vm.clickedSubmit
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v(" Start Now ")])], 1)], 1)], 1) : _c('div', {
    staticClass: "text-body-1 black--text"
  }, [_vm._v(" Thank you for subscribing! We will let you know when we release! ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }