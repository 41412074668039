var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    "class": [_vm.$vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4', 'pb-16']
  }, [_vm._m(0), _c('v-row', {
    staticClass: "py-10 px-4",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.plans, function (plan, i) {
    return _c('v-col', {
      key: i,
      staticClass: "mx-5",
      attrs: {
        "cols": 10,
        "sm": 5,
        "lg": 3
      }
    }, [_c('v-card', {
      staticClass: "rounded-xl d-flex flex-column",
      attrs: {
        "height": "100%",
        "elevation": "10"
      }
    }, [_c('v-card-title', [_c('div', {
      staticClass: "text-lg-h4 text-md-h5 font-weight-medium py-5"
    }, [_vm._v(" " + _vm._s(plan.title) + " ")]), _c('div', {
      staticClass: "text-subtitle-2 py-5 pr-8"
    }, [_vm._v(" " + _vm._s(plan.subtitle) + " ")])]), _c('v-card-subtitle'), _c('v-card-text', {
      staticClass: "py-5 black--text"
    }, [_c('h1', {
      staticClass: "font-weight-medium pb-5"
    }, [_vm._v(" ₹" + _vm._s(_vm.formatCurrency(plan.price))), _c('span', {
      staticClass: "text-subtitle-1"
    }, [_vm._v("/month")])]), _c('div', {}, _vm._l(plan.features, function (feat, i) {
      return _c('v-checkbox', {
        key: "".concat(plan.title, "-").concat(feat.text, "-").concat(i),
        attrs: {
          "readonly": "",
          "label": feat.text,
          "color": feat.active ? 'info' : '',
          "value": feat.active,
          "disabled": !feat.active,
          "hide-details": ""
        },
        model: {
          value: feat.active,
          callback: function callback($$v) {
            _vm.$set(feat, "active", $$v);
          },
          expression: "feat.active"
        }
      });
    }), 1)]), _c('v-spacer'), _c('v-card-actions', {
      staticClass: "justify-center pb-6"
    }, [_c('v-btn', {
      staticClass: "mr-5 px-4 py-2",
      attrs: {
        "rounded": "",
        "color": "#165FD8"
      },
      on: {
        "click": function click($event) {
          return _vm.scrollToId('camp__signup');
        }
      }
    }, [_c('span', {
      staticStyle: {
        "color": "white"
      }
    }, [_vm._v(" Start Free Trial ")])])], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-h3 text-center"
  }, [_vm._v(" Pick a plan that works for you "), _c('div', {
    staticClass: "price__title__underline"
  })]);
}]

export { render, staticRenderFns }