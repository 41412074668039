<template>
  <nav class="top__nav">
    <!-- for large screens -->
    <v-app-bar fixed class="hidden-sm-and-down">
      <v-list-item-avatar class="ml-16">
        <router-link to="/">
          <v-avatar color="#165FD8">
            <v-icon color="white" size="42px" class="pr-2 pl-1 pb-1">
              {{ mdiConsoleLine }}
            </v-icon>
          </v-avatar>
        </router-link>
      </v-list-item-avatar>
      <v-app-bar-title class="text-h5 font-weight-medium"> 
        <div class="pr-8"> CodeDrills </div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-5"
        rounded
        color="#165FD8"
        v-for="item in menuItems"
        @click="!!item.clickId && scrollToId(`${item.clickId}`)"
        :key="item.title"
        :href="item.path ? item.path : ''"
      >
        <!-- find a better way to customize -->
        <span class="white--text">
          {{ item.title }}
        </span>
      </v-btn>
    </v-app-bar>
    <!-- for mobiles -->
    <v-app-bar fixed class="hidden-md-and-up">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-list-item-avatar class="mx-2">
        <router-link to="/">
          <v-avatar color="#165FD8">
            <v-icon color="white" size="42px" class="pr-2 pl-1">
              {{ mdiConsoleLine }}
            </v-icon>
          </v-avatar>
        </router-link>
      </v-list-item-avatar>
      <v-app-bar-title class="text-h5 font-weight-medium"> 
        <div> CodeDrills </div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer fixed v-model="drawer">
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import { mdiConsoleLine } from "@mdi/js";
import scrollToMixin from "../../mixins/scrollToMixin";

export default {
  data() {
    return {
      mdiConsoleLine,
      drawer: false,
      menuItems: [
        {
          title: "Start Hiring",
          clickId: "camp__signup"
        },
        {
          title: "Contact Us",
          path: "mailto:hello@codedrills.io",
        },
      ],
    };
  },
  mixins: [scrollToMixin]
};
</script>
<style scoped>
</style>